
import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

import type { Seller } from "@/store/modules/seller.store";
import { ActivitySeller } from "@/store/modules/activity-seller.store";

@Component
export default class PageSellerContacts extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("seller/all") sellers!: Seller[];
    @Getter("activity-seller/all") activitysellers!: ActivitySeller[];

    get sellersExcludingSelf() {
        return this.sellers.filter((seller: Seller) => Number(seller.account_id) !== Number(this.me.id) && this.activitysellers.filter((as) => Number(as.seller_id) === Number(seller.id) && as.dashboard_access).length !== 0);
    }
}
